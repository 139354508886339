import { SignedURL, FetchSignedURLOptions } from './models/analytics.model';

/**
 * Fetch a signed analytics URL from the API
 */
export const fetchSignedURL = async ({ apiAuthToken, apiBaseURL, controls, organizationId, parameters, url }: FetchSignedURLOptions): Promise<SignedURL> => {
  const response = await fetch(`${apiBaseURL}/analytics/signed-urls`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${apiAuthToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      controls,
      organization_id: organizationId,
      parameters,
      url,
    }),
  });

  const data = await response.json();
  return data as SignedURL;
};
